import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import moment from "moment"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled.div`
  padding: 20px;
  background-color: #ffff;

  font-size: 0.9em;

  small {
    font-size: 14px;
    color: #91d8f7;
  }
`

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const { title, date } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO title={title} />
      <Content>
        <small>{moment(date).format("DD-MMMM-YYYY")}</small>
        <h1>{title}</h1>

        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </Content>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`
